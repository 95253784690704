/**
 * Dev environment
 *
 * Put what you like in it, but DON'T use it to build prod !
 */
export const environment = {
  production: false,
  title: 'Weezchat, unlimited chat',
  api: 'https://chat-api-new-pp.contactdve.com/api',
  chat: 'https://pp-chat-liveengine.contactdve.com/',
  extCode: 'UK',
  key: 1,
  newsSectionUrl: null,
  footerText: null,
  footerLinks: [
    { value: 'Contact', href: 'https://m.weezchat.co.uk/contact' },
    { value: 'Terms', href: 'https://m.weezchat.co.uk/terms' },
    { value: 'F.A.Q.', href: 'https://m.weezchat.co.uk/faq' },
    { value: 'Legal', href: 'https://m.weezchat.co.uk/legal' },
    { value: 'Personal data', href: 'https://m.weezchat.co.uk/personal' },
    { value: 'MY ACCOUNT', href: 'https://m.weezchat.co.uk/mon_compte' },
    { value: 'Cookies', href: 'https://m.weezchat.co.uk/cookies' },
  ],
  LP: null,
  redirectIfNoIframe: null,
  useBothSignUpMethod: false,
  avatarSize: 1200,
  gtm: 'GTM-PDGPX74',
  showAllCities: true,
  RTL: false,
  completeProfileInputs: ['email'],
  unsubscribeLink: false,
freeSubscription : false,
  home: {
    app: {
      available: false,
      linkGoogle: '',
      linkApple: '',
    },
    facebook: {
      available: false,
      link: ''
    },
    twitter: {
      available: false,
      link: ''
    },
    instagram: {
      available: false,
      link: ''
    }
  },
  RGPD : {
    cookies: {
      available: true,
      link: 'https://m.weezchat.co.uk/cookies'
    }
  }
};
